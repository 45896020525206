<template>
  <section class="section">
    <div class="container">
      <div class="row">
        <div class="col-lg-6">
          <img src="../../assets/images/about.jpg" alt="about Img" class="img-fluid about-img" />
        </div>

        <div class="col-lg-6">
          <div class="about-txt">
            <div class="title-box">
              <h2 class="text-center">{{$t("Initial setup")}}</h2>
              <div class="border"></div>
            </div>

            <p>{{$t("The initial configuration consists of the purchase and configuration of domains and external platforms for the development of the project, which we can develop internally or give the client instructions for him to do it, An example of these configurations are: email services like Gmai (Gsuite), Zoho or Hotmail.")}}</p>
            <p>{{$t("This process also includes the configuration of the continuous integration service (CI / CD), which consists of automating the test stages and deployment of new code automatically, using tools such as Gitlab, Github, Rancher, Kubernetes, AWS, GCP, among others.")}}</p>
            <div class="row align-items-center">
              <div class="col-2">
                <img src="../../assets/images/technologies/aws.png" alt="aws" class="img-icon" />
              </div>
              <div class="col-2">
                <img src="../../assets/images/technologies/gcp.jpeg" alt="gcp" class="img-icon" />
              </div>
              <div class="col-2">
                <img
                  src="../../assets/images/technologies/gitlab.png"
                  alt="gitlab"
                  class="img-icon"
                />
              </div>
              <div class="col-2">
                <img
                  src="../../assets/images/technologies/rancher.png"
                  alt="rancher"
                  class="img-icon"
                />
              </div>
              <div class="col-2">
                <img
                  src="../../assets/images/technologies/docker.png"
                  alt="docker"
                  class="img-icon"
                />
              </div>

              <div class="col-2">
                <img
                  src="../../assets/images/technologies/kubernets.png"
                  alt="kubernets"
                  class="img-icon"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "InitialSetup"
};
</script>
